import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  IQuestionForExample,
  Library,
  Document,
} from "../../models/interfaces";
import axios from "axios";

const appearanceSlice = createSlice({
  name: "appearance",
  initialState: {
    preview: false,
    isPrivate: false,
    explanator: true,
    // logo
    logoSrc: null as string | null,
    logoHref: null as string | null,
    // home
    backgroundImage: null as string | null,
    bgPortraitImage: null as string | null,
    backgroundSize: "cover",
    questionsForExample: [] as IQuestionForExample[],
    contactUsDestinationLink: "",
    fileRepositoryDestinationLink: "",

    enableQuestionsForExample: false,
    enableContactUsDestinationLink: false,
    enableFileRepositoryDestinationLink: false,
    // search
    searchBarPlaceHolder: null as string | null,
    disableFilters: false,
    mode: null as string | null,
    loaded: false,
    highlightPdf: true,
    experienceName: "Korra Knowledge Experience",
    hideFileName: false,
    showManyHeaders: false,
    max_per_subject: 0,
    narrowfactor: 0,
    rtlenabled: false,
    yearRangeEnabled: false,
    yearRangeEnabled2: false,
    enableArticlesLibrary: false,
    enableUpdatedArticles: false,
    updatedArticlesExpireListCount: null as number | null,
    articleForExample: {},
    libraries: [] as Library[],
    allDocuments: [] as Document[],
    updatedDocuments: [] as Document[],
    eventType: "",
    backHome: false,
    vettedResults: false,
    showRelatedDocuments: false,
    showKeywordFilter: false,
    mediaTypeClientSideFiltering: false,
    filtersType: "category",
    showHomePageFilter: false
  },
  reducers: {
    setShowHomePageFilter: (state, action: PayloadAction<boolean>) => {
      state.showHomePageFilter = action.payload;
    },
    setFiltersType: (state, action: PayloadAction<string>) => {
      state.filtersType = action.payload;
    },
    setMediaTypeClientSideFiltering: (state, action: PayloadAction<boolean>) => {
      state.mediaTypeClientSideFiltering = action.payload;
    },
    setShowKeywordFilterFlag: (state, action: PayloadAction<boolean>) => {
      state.showKeywordFilter = action.payload;
    },
    setVettedResultsFlag: (state, action: PayloadAction<boolean>) => {
      state.vettedResults = action.payload;
    },
    setIsPrivate: (state, action: PayloadAction<boolean>) => {
      state.isPrivate = action.payload;
    },
    setLogoSrc: (state, action: PayloadAction<string>) => {
      state.logoSrc = action.payload;
    },
    setLogoHref: (state, action: PayloadAction<string>) => {
      state.logoHref = action.payload;
    },
    setBackgroundImage: (state, action: PayloadAction<string>) => {
      state.backgroundImage = action.payload;
    },
    setBgPortraitImage: (state, action: PayloadAction<string>) => {
      state.bgPortraitImage = action.payload;
    },
    setBackgroundSize: (state, action: PayloadAction<string>) => {
      state.backgroundSize = action.payload;
    },
    setMode: (state, action: PayloadAction<string>) => {
      state.mode = action.payload;
    },
    setLoaded: (state, action: PayloadAction<boolean>) => {
      state.loaded = action.payload;
    },
    setQuestionsForExample: (
      state,
      action: PayloadAction<IQuestionForExample[]>
    ) => {
      state.questionsForExample = action.payload;
    },
    setContactUsDestinationLink: (state, action: PayloadAction<string>) => {
      state.contactUsDestinationLink = action.payload;
    },
    setFileRepositoryDestinationLink: (
      state,
      action: PayloadAction<string>
    ) => {
      state.fileRepositoryDestinationLink = action.payload;
    },
    setEnableContactUsDestinationLink: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.enableContactUsDestinationLink = action.payload;
    },
    setEnableFileRepositoryDestinationLink: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.enableFileRepositoryDestinationLink = action.payload;
    },
    setEnableQuestionsForExample: (state, action: PayloadAction<boolean>) => {
      state.enableQuestionsForExample = action.payload;
    },
    setExplanator: (state, action: PayloadAction<boolean>) => {
      state.explanator = action.payload;
    },
    setDisableFilters: (state, action: PayloadAction<boolean>) => {
      state.disableFilters = action.payload;
    },
    setSearchBarPlaceHolder: (state, action: PayloadAction<string>) => {
      state.searchBarPlaceHolder = action.payload;
    },
    setHighlightPdf: (state, action: PayloadAction<boolean>) => {
      state.highlightPdf = action.payload;
    },
    setExperienceName: (state, action: PayloadAction<string>) => {
      state.experienceName = action.payload;
    },
    setHideFileName: (state, action: PayloadAction<boolean>) => {
      state.hideFileName = action.payload;
    },
    setShowManyHeaders: (state, action: PayloadAction<boolean>) => {
      state.showManyHeaders = action.payload;
    },
    setMaxPerSubject: (state, action: PayloadAction<number>) => {
      state.max_per_subject = action.payload;
    },
    setPreview: (state, action: PayloadAction<boolean>) => {
      state.preview = action.payload;
    },
    setNarrowFactor: (state, action: PayloadAction<number>) => {
      state.narrowfactor = action.payload;
    },
    setIsRTL: (state, action: PayloadAction<boolean>) => {
      state.rtlenabled = action.payload;
    },
    setYearRangeEnabled: (state, action: PayloadAction<boolean>) => {
      state.yearRangeEnabled = action.payload;
    },
    setYearRangeEnabled2: (state, action: PayloadAction<boolean>) => {
      state.yearRangeEnabled2 = action.payload;
    },
    setArticleForExample: (state, action: PayloadAction<object>) => {
      state.articleForExample = action.payload;
    },
    setEnableArticlesLibrary: (state, action: PayloadAction<boolean>) => {
      state.enableArticlesLibrary = action.payload;
    },
    setEnableUpdatedArticles: (state, action: PayloadAction<boolean>) => {
      state.enableUpdatedArticles = action.payload;
    },
    setUpdatedArticlesExpireListCount: (
      state,
      action: PayloadAction<number | null>
    ) => {
      state.updatedArticlesExpireListCount = action.payload;
    },
    setLibraries: (state, action: PayloadAction<Library[]>) => {
      state.libraries = action.payload;
    },
    setAllDocuments: (state, action: PayloadAction<Document[]>) => {
      state.allDocuments = action.payload;
    },
    setUpdatedDocuments: (state, action: PayloadAction<Document[]>) => {
      state.updatedDocuments = action.payload;
    },
    setEventType: (state, action: PayloadAction<string>) => {
      state.eventType = action.payload;
    },
    setBackHome: (state, action: PayloadAction<boolean>) => {
      state.backHome = action.payload;
    },
    setShowRelatedDocuments: (state, action: PayloadAction<boolean>) => {
      state.showRelatedDocuments = action.payload;
    },
  },
});

export const {
  setIsPrivate,
  setLogoSrc,
  setLogoHref,
  setBackgroundImage,
  setBgPortraitImage,
  setBackgroundSize,
  setMode,
  setLoaded,
  setQuestionsForExample,
  setExplanator,
  setContactUsDestinationLink,
  setFileRepositoryDestinationLink,
  setEnableContactUsDestinationLink,
  setEnableFileRepositoryDestinationLink,
  setEnableQuestionsForExample,
  setDisableFilters,
  setSearchBarPlaceHolder,
  setHighlightPdf,
  setExperienceName,
  setHideFileName,
  setShowManyHeaders,
  setMaxPerSubject,
  setPreview,
  setNarrowFactor,
  setIsRTL,
  setYearRangeEnabled,
  setYearRangeEnabled2,
  setEnableArticlesLibrary,
  setEnableUpdatedArticles,
  setUpdatedArticlesExpireListCount,
  setArticleForExample,
  setAllDocuments,
  setLibraries,
  setUpdatedDocuments,
  setEventType,
  setBackHome,
  setVettedResultsFlag,
  setShowRelatedDocuments,
  setShowKeywordFilterFlag,
  setMediaTypeClientSideFiltering,
  setFiltersType,
  setShowHomePageFilter
} = appearanceSlice.actions;
export default appearanceSlice.reducer;