import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  SearchResultType,
  SelectedFilters,
  Summary,
  Tag,
  SelectedItem
} from "../../models/interfaces";
import { SelectedDocumentType } from "../../models/enums";
import { initialSelectedFilters } from "../../models/consts";

export const initialState = {
  lastQ: "",
  exactMatch: "",
  lastQResults: null as any,
  lastSelectedFilters: initialSelectedFilters,
  lastExactMatch: "",
  kapps: [] as any[],
  summary: null as Summary | null,
  chabotResults: [] as SearchResultType[] | null,
  tags: [] as Tag[],
  selectedFilters: initialSelectedFilters,
  isChronological: false,
  chronologicalOrder: false,
  searchResltsBackup: [] as any[],
  typedYearFrom: "",
  typedYearTo: "",
  yearFilterStatus: false,
  docsFilterStatus: false,
  categories: [] as any,
  selectedCategory: [] as SelectedItem[],
  inputValue: ""
};

const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setInputValue: (state, action: PayloadAction<any>) => {
      state.inputValue = action.payload;
    },
    setSelectedCategory: (state, action: PayloadAction<any>) => {
      state.selectedCategory = action.payload;
    },
    setCategories: (state, action: PayloadAction<any>) => {
      state.categories = action.payload;
    },
    setLastQ: (state, action: PayloadAction<string>) => {
      state.lastQ = action.payload;
    },
    setExactMatch: (state, action: PayloadAction<string>) => {
      state.exactMatch = action.payload;
    },
    setLastExactMatch: (state, action: PayloadAction<string>) => {
      state.lastExactMatch = action.payload;
    },
    setLastQResults: (state, action: PayloadAction<any>) => {
      state.lastQResults = action.payload;
    },
    setKapps: (state, action: PayloadAction<any[]>) => {
      state.kapps = action.payload;
    },
    setSummary: (state, action: PayloadAction<Summary | null>) => {
      state.summary = action.payload;
    },
    setChatbotResults: (state, action: PayloadAction<SearchResultType[] | null>) => {
      state.chabotResults = [
        ...(state.chabotResults ?? []),
        ...(action.payload ?? [])
      ];
    },
    setSummaryText: (state, action: PayloadAction<string>) => {
      if (state.summary) {
        state.summary.text = action.payload;
      }
    },
    setSummaryStyle: (state, action: PayloadAction<string>) => {
      if (state.summary) {
        state.summary.style = action.payload;
      }
    },
    setTags: (state, action: PayloadAction<Tag[]>) => {
      state.tags = action.payload;
    },
    setSelectedFilters: (state, action: PayloadAction<SelectedFilters>) => {
      state.selectedFilters = action.payload;
    },
    setLastSelectedFilters: (state, action: PayloadAction<SelectedFilters>) => {
      state.lastSelectedFilters = action.payload;
    },
    setIsChronological: (state, action: PayloadAction<boolean>) => {
      state.isChronological = action.payload;
    },
    setChronologicalOrder: (state, action: PayloadAction<boolean>) => {
      state.chronologicalOrder = action.payload;
    },
    setSearchResltsBackup: (state, action: PayloadAction<any[]>) => {
      state.searchResltsBackup = action.payload;
    },
    setTypedYearFrom: (state, action: PayloadAction<string>) => {
      state.typedYearFrom = action.payload;
    },
    setTypedYearTo: (state, action: PayloadAction<string>) => {
      state.typedYearTo = action.payload;
    },
    setYearFilterStatus: (state, action: PayloadAction<boolean>) => {
      state.yearFilterStatus = action.payload;
    },
    setDocsFilterStatus: (state, action: PayloadAction<boolean>) => {
      state.docsFilterStatus = action.payload;
    },
  },
});

export const {
  setLastQ,
  setExactMatch,
  setLastQResults,
  setKapps,
  setSummary,
  setSummaryText,
  setSummaryStyle,
  setTags,
  setSelectedFilters,
  setLastSelectedFilters,
  setIsChronological,
  setChronologicalOrder,
  setSearchResltsBackup,
  setTypedYearFrom,
  setTypedYearTo,
  setYearFilterStatus,
  setDocsFilterStatus,
  setLastExactMatch,
  setChatbotResults,
  setCategories,
  setSelectedCategory,
  setInputValue
} = searchSlice.actions;
export default searchSlice.reducer;
